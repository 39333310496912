import { createStore } from "vuex";

export default createStore({
  state: {
    project : 'JamSplit',
    desc : '',
    user: null,
    profile: null,
    theme: 'light',
    genders: ['Male', 'Female', 'Non-Binary', 'Prefer Not to Say'],
    levels: ['Beginner', 'Intermediate', 'Advanced', 'Professional'],
    instruments: [
      'Acoustic Guitar',
      'Electric Guitar',
      'Bass',
      'Drums',
      'Piano/Keyboard',
      'Vocals',
      'Saxophone',
      'Trumpet',
      'Trombone',
      'Harmonica',
    ],
    genres: [
      'Rock',
      'Pop',
      'Jazz',
      'Blues',
      'Country',
      'Folk',
      'Metal',
      'Hip-Hop',
      'R&B/Soul',
      'Reggae',
      'Funk',
      'Gospel',
      'Punk',
      'Bluegrass',
      'Latin',
    ],
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
});
 